import React, { useEffect, useState } from 'react';
import { ColDef, DataGrid } from '@material-ui/data-grid';
import { Box, Button, Collapse, Drawer, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Keycloak from 'keycloak-js';

const keycloak = Keycloak({
  url: 'https://auth.metatavu.io/auth',
  realm: 'casemet',
  clientId: 'analyzer'
});

function AnalysisResultRow(props: {analysisResult: any}) {
  const { analysisResult } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {analysisResult.worknumber}
        </TableCell>
        <TableCell align="right">{analysisResult.code}</TableCell>
        <TableCell align="right">{analysisResult.description}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2}>
              <Typography variant="h5">Work items</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Desc</TableCell>
                    <TableCell>Ready</TableCell>
                    <TableCell align="right">Days late</TableCell>
                    <TableCell align="right">Has risk</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analysisResult.analysisResultItems.map((resultRow: any) => (
                    <TableRow key={resultRow.id}>
                      <TableCell component="th" scope="row">
                        {resultRow.description}
                      </TableCell>
                      <TableCell>{resultRow.isReady ? "yes" : "no"}</TableCell>
                      <TableCell align="right">{resultRow.daysLate}</TableCell>
                      <TableCell style={resultRow.risk ? {color: "rgb(255, 0, 0)"} : {}} align="right">
                        {resultRow.risk ? "yes" : "no"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
            <Box margin={2}>
              <Typography variant="h5">Material</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Description</TableCell>
                    <TableCell>Code</TableCell>
                    <TableCell>Available</TableCell>
                    <TableCell>Required</TableCell>
                    <TableCell align="right">Missing</TableCell>
                    <TableCell align="right">Has risk</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analysisResult.materialAnalysisResultItems.map((materialResultRow: any) => (
                    <TableRow key={materialResultRow.id}>
                      <TableCell component="th" scope="row">
                        {materialResultRow.materialDescription}
                      </TableCell>
                      <TableCell>{materialResultRow.materialCode}</TableCell>
                      <TableCell>{materialResultRow.availableCount}</TableCell>
                      <TableCell>{materialResultRow.requiredCount}</TableCell>
                      <TableCell align="right">{-1.0 * materialResultRow.missingCount}</TableCell>
                      <TableCell style={materialResultRow.risk ? {color: "rgb(255, 0, 0)"} : {}} align="right">
                        {materialResultRow.risk ? "yes" : "no"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function App() {
  const [openOrders, setOpenOrders] = useState<any[]>([]);
  const [analysisResult, setAnalysisResult] = useState<any[]>([]);
  const [accessToken, setAccessToken] = useState<string>("");
  useEffect(() => {
    keycloak.init({ checkLoginIframe: false, onLoad: 'login-required' })
      .then((authenticated) => {
        if (authenticated && keycloak.token) {
          setAccessToken(keycloak.token);
        }
      })
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetch(process.env.REACT_APP_API_URL || "", {headers: { "Authorization": `Bearer ${accessToken}` } })
        .then(r => r.json())
        .then(data => setOpenOrders(data))
        .catch(e => console.error(e))
    }
  }, [accessToken]);

  const colums: ColDef[] = [
    {
      field: "orderNumber",
      headerName: "Order number",
      width: 200
    },
    {
      field: "orderNumber2", 
      headerName: "Actions",
      width: 200,
      renderCell: ((params) => {
        return (
          <Button onClick={() => {
            console.log(params.value);
            const items = openOrders.filter(o => o.orderNumber === params.value)
            setAnalysisResult(items)
          }}>Open</Button>
        )
      })
    }
  ];

  const orders = openOrders.map(o => o.orderNumber).filter((v, i, a) => a.indexOf(v) === i);

  return (
    <div className="App">
      <Drawer anchor="right" onClose={() => { setAnalysisResult([]); }} open={analysisResult.length > 0}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>WorkNumber</TableCell>
              <TableCell align="right">Code</TableCell>
              <TableCell align="right">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {analysisResult.map((result) => (
              <AnalysisResultRow key={result.code} analysisResult={result} />
            ))}
          </TableBody>
        </Table>
      </Drawer>
      <div style={{ paddingTop: "5vh", height: '90vh', width: '50%' }}>
        <DataGrid columns={colums} rows={orders.map((o, i) => { return {orderNumber: o, orderNumber2: o, id: i} })} />
      </div>
    </div>
  );
}

export default App;
